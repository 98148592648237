/* eslint-disable react/no-string-refs */
//
import React, { useState, useEffect, useRef } from 'react'
import viewport from '../../../../util/viewport'
import dragscrollReset from './dragscroll'

import Picture from '../Picture'
import Side_Scroll_svg from '../../../../images/Icons/Side_Scroll.svg'

const ImageCaption = (props) => {
  let { style, copy } = props;

  // Create a new object for the modified style
  const newStyle = style ? { ...style, textAlign: 'left' } : { textAlign: 'left' };

  return (
    <div
      className="caption"
      style={newStyle}
      dangerouslySetInnerHTML={{ __html: copy }}
    />
  );
};


const ImageScroller = (props) => {
  const [swipeDirection, setSwipeDirection] = useState('')
  const [xDown, setXDown] = useState(null)

  const ref = useRef("track")

  useEffect(() => {
    //if (viewport.getCurrentQuery().startsWith('desktop')) {
    dragscrollReset()
    //}

    window.addEventListener('resize', centeredFirstImage)

    const firstImages = document.querySelectorAll('.card[data-index="1"] img')
    for (const image of firstImages) {
      image.addEventListener('load', centeredFirstImage)
    }
  }, [])

  const centeredFirstImage = () => {
    const firstCards = document.querySelectorAll('.card[data-index="1"]')
    const firstCardsArray = Array.from(firstCards)
    firstCardsArray.map((firstCard) => {
      const firstImage = firstCard.querySelector('img')
      if (firstImage) {
        const imageWidth = firstImage.clientWidth
        let marginVal

        if (viewport.getCurrentQuery() === 'phone') {
          marginVal = `calc(100vw - 15px - (100vw)/2 - ${imageWidth / 2}px)`
        } else if (viewport.getCurrentQuery() === 'tablet') {
          marginVal = `calc(100vw - 45px - (100vw)/2 - ${imageWidth / 2}px)`
        } else {
          marginVal = `calc(100vw - 385px - (100vw - 317px)/2 - ${
            imageWidth / 2
          }px)`
        }
        // firstCard.style.cssText = `margin-left: ${marginVal}`;
      }
    })
  }

  const handleTouchStart = (evt) => {
    const touches = evt.touches ? evt.touches[0] : evt
    setXDown(touches.clientX)
  }

  const handleTouchMove = (evt) => {
    if (!xDown) {
      return
    }
    const touches = evt.touches ? evt.touches[0] : evt
    const xUp = touches.clientX
    const xDiff = xDown - xUp
    let swipe = ''

    if (Math.abs(xDiff)) {
      const leftValue =
        viewport.getCurrentQuery() === 'desktop' ? 'slide_left' : 'swipe_left'
      const rightValue =
        viewport.getCurrentQuery() === 'desktop' ? 'slide_right' : 'swipe_right'

      swipe = xDiff > 0 ? leftValue : rightValue
      handleCardClick(evt, swipe)
    }

    setXDown(null)
  }

  const handleCardClick = (evt, swipe) => {
    // debugger;
    const idx = evt.currentTarget.getAttribute('data-index')
    const count = evt.currentTarget.getAttribute('data-count')

    // const typeTitle = (this.props.section.components[this.props.index - 1] && this.props.section.components[this.props.index - 1].title
    //   ? this.props.section.components[this.props.index - 1].title
    //   : this.props.section.title).replace(/[^a-zA-Z ]/g, '').trim();
  }

  const { style, cardStyle, spacing, images } = props

  let classes = 'card'
  if (spacing === 'tight') {
    classes += ' tight-spacing'
  }
  let imagesHTML = images.map((image, index) => (
    <div
      className={classes}
      style={cardStyle}
      key={`image-scroller-${index}`}
      data-index={index + 1}
      data-count={images.length}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onMouseDown={handleTouchStart}
      onMouseMove={handleTouchMove}>
      {image.header && (
        <ImageCaption copy={image.header.copy} style={image.header.style} />
      )}
      <div className={`image ${image.image.imageSize}`}>
        <Picture image={image.image} />
      </div>
      {image.caption && (
        <ImageCaption copy={image.caption.copy} style={image.caption.style} />
      )}
    </div>
  ))

  return (
    <div className="image-scroller" style={style} data-compid={props.compid}>
      <div className="scrollbar-mask">
        <div
          className="container dragscroll"
          style={{ cursor: `url(${Side_Scroll_svg}), auto` }}>
          <div className="track" ref={ref}>
            {imagesHTML}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageScroller
