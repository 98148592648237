import React from "react";
import ContentBlock from "../components/v2/ContentBlock";

import { pageLinks } from "../data/pageLinks";

import Layout from "../components/v2/Layout";
import PageSection from "../components/v2/PageSection";
import ImageScroller from "../components/v2/ui-blocks/ImageScroller";
import Image from "../components/v2/Image";

import hero_img from "../images/12. Product Campaigns/Product Campaigns Header – C-HR – 1920x680.jpg";

import product_campaigns_layout_example_1 from "../images/12. Product Campaigns/Product Campaigns – Layout examples – Corolla Cross – 1280px.jpg";
import product_campaigns_layout_example_2 from "../images/12. Product Campaigns/Product Campaigns – Layout examples – Hilux Vehicle Logo – 1280px.jpg";
import product_campaigns_layout_example_3 from "../images/12. Product Campaigns/Product Campaigns – Layout examples – Hilux Primary Toyota Logo – 1280px.jpg";

import BC_Colours from "../images/12. Product Campaigns/BC_Colours.jpg";
import Hilux_1 from "../images/12. Product Campaigns/BC_Displayfont_Hilux_1.png";
import Hilux_2 from "../images/12. Product Campaigns/Product Campaigns – Display Fonts – Hilux – 1180x240.jpg";
import Yaris_1 from "../images/12. Product Campaigns/BC_Displayfont_Yaris_1.png";
import Yaris_2 from "../images/12. Product Campaigns/BC_Displayfont_Yaris_2.png";
import Yaris_3 from "../images/12. Product Campaigns/BC_Displayfont_Yaris_3.png";
import PrevNextNavFooter from "../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

const ProductCampaigns = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <div className="hero-img">
        <Image width="100%" src={hero_img} />
      </div>
      <PageSection sectionId="overview">
        <ContentBlock>
          <h2>Product Campaigns</h2>
          <div className="text">
            <p>The following pages provide guidelines for Product Campaigns.</p>
            <p>
              Whether a vehicle or a sub-brand is classed as Product Campaign
              will be determined at the briefing stage. They will often include,
              but are not limited to, campaigns for our brand icons: Hilux,
              RAV4, Corolla and Yaris.
            </p>
            <p>
              There is plenty of room for flexibility to allow for their own
              distinctive look and tone, but there are still some guidelines to
              follow to ensure it feels like a ‘Toyota’ campaign.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="display-fonts">
        <ContentBlock noTopSpacing={true}>
          <h2>DISPLAY FONTS</h2>
          <div className="text">
            <p>
              When it comes to Product Campaigns, typography plays an important
              part in expressing an idea or mood. Because of this, there is the
              flexibility to branch outside of Toyota Type and choose a
              different display font for headlines or subheads. There is also
              the option to use any font in the Toyota Type family if it works
              for the campaign idea.
            </p>
            <p>
              Toyota Type will still be used as the secondary typeface for all
              other copy ie. body copy, legal text etc.
            </p>
            <p>
              Below are some examples of Product Campaigns that utilise unique
              display fonts.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: Hilux_1,
                style: { maxHeight: "50vh", maxWidth: "70vw" }
              }
            },
            {
              image: {
                svg: Hilux_2,
                style: { maxHeight: "50vh", maxWidth: "70vw" }
              }
            },
            {
              image: {
                svg: Yaris_1,
                style: { maxHeight: "50vh", maxWidth: "70vw" }
              }
            },
            {
              image: {
                svg: Yaris_2,
                style: { maxHeight: "50vh", maxWidth: "70vw" }
              }
            },
            {
              image: {
                svg: Yaris_3,
                style: { maxHeight: "50vh", maxWidth: "70vw" }
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PageSection sectionId="campaign-colours">
        <ContentBlock>
          <h2>CAMPAIGN COLOURS</h2>
          <div className="text">
            <p>
              Product Campaigns don’t need to adhere to the Toyota colour pallet
              (red, white and black). A new colour pallet can be created to suit
              the campaign idea.
            </p>
            <p>
              Campaign colours can be created by taking cues from other colours
              featured in the artwork. For example, from imagery or textures.
            </p>
            <p>
              These colours can also be used as tints to help ensure there’s
              plenty of contrast between key messaging, logos and graphics.
            </p>
          </div>
        </ContentBlock>
        <ContentBlock></ContentBlock>
        <Image width="full" src={BC_Colours} />
      </PageSection>
      <PageSection sectionId="layout-examples">
        <ContentBlock>
          <h2>Layout Examples</h2>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: product_campaigns_layout_example_1,
                style: { height: "auto", width: "70vw", maxWidth: 800, maxHeight: 800 }
              },
              caption: {
                copy: 'Example of where we put the  staging platform + vehicle badge + <em>Let’s&nbsp;Go&nbsp;Places</em>'
              }
            },
            {
              image: {
                svg: product_campaigns_layout_example_3,
                style: { height: "auto", width: "70vw", maxWidth: 800, maxHeight: 800 }
              },
              caption: {
                copy: 'Example of where vehicle badge is used and therefore Primary Toyota / <em>Let’s&nbsp;Go&nbsp;Places</em> logo is used'
              }
            },
            {
              image: {
                svg: product_campaigns_layout_example_2,
                style: { height: "auto", width: "70vw", maxWidth: 800, maxHeight: 800 }
              },
              caption: {
                copy: 'Example of when a vehicle badge / <em>Let’s&nbsp;Go&nbsp;Places</em> logo is used'
              }
            },
          ]}></ImageScroller>
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Hey Toyota"
        previousUrl={pageLinks["hey-toyota"]}
        nextText="Next"
        nextHeader="Partnerships"
        nextUrl={pageLinks.partnerships}
      />
    </Layout>
  );
};

export default ProductCampaigns;